export default {
    methods: {
        getHeadersTabella() {
            return [{
                text: "Data",
                value: "dataFormat",
                sortable: true,
                sort: (a, b) => {
                    return new Date(b) - new Date(a);
                }
            },
            {
                text: "Operatore",
                value: "operatore"
            },
            {
                text: "Cliente",
                value: "cliente"
            },
            {
                text: "Destinazione",
                value: "destinazione"
            },
            {
                text: "Ore",
                value: "ore"
            },
            {
                text: "Tipo importo",
                value: "tipoImporto"
            },
            {
                text: "Importo orario",
                value: "importo"
            },
            {
                text: "Importo totale",
                value: "importoTotale"
            },
            ]
        },

        getHeadersTabellaRaggruppa() {
            return [{
                text: "Operatore",
                value: "operatore"
            },
            {
                text: "Cliente",
                value: "cliente"
            },
            {
                text: "Destinazione",
                value: "destinazione"
            },
            {
                text: "Ore",
                value: "ore"
            },
            {
                text: "Importo totale",
                value: "importoTotale"
            },
            ]
        },

        getDestinazioni() {
            return ["Tutte", "Cliente", "Ufficio"]
        },
        getTipiOre() {
            return ["Lavoro", "Ferie", "Mutua", "Permesso"]
        }
    }
}