<template src="./profilo.html"></template>

<script>
import {
    save_userAccount,
    save_immagineAccount,
    get_immagineAccount,
    del_immagineAccount,
    /* visualizzaUtente,
    modificaUtente, */
} from "@/api/api.user.js";

import storage from "../../services/storage";
import ImageInput from "@/components/ImageInput.vue";

export default {
    components: {
        ImageInput,
    },

    data: () => ({
        e1: false,
        e2: false,
        validFormUtente: true,
        disabilitaBtnSaveAccount: false,
        alertElaborazione: "",
        tipo: "success",

        datiAccount: {
            password: "",
            passwordNew: "",
            passwordRepeat: "",
            username: ""
        },

        avatar: null,
        saving: false,
        saved: false,
    }),
    methods: {
        async salvaDati() {
            // Validazione
            if (
                this.$refs.formPwd.validate() == false ||
                this.$refs.formUtente.validate() == false
            ) {
                return;
            }

            this.datiAccount.username = this.userName;
            let res = await save_userAccount(this.datiAccount);
            if (res.data == "OK") {
                this.alertElaborazione =
                    "Dati aggiornati. Per rendere effettive le modifiche uscire e rientrare nell'area riservata.";
                this.tipo = "success";
                this.datiAccount.password = "";
                this.datiAccount.passwordNew = "";
                this.datiAccount.passwordRepeat = "";
            }
            this.disabilitaBtnSaveAccount = false;
        },

        async caricaAvatar() {
            //carico l'immagine dell'utente se presente salvo il percorso nello storage
            if (!this.getUserId) return;
            let res = await get_immagineAccount(this.getUserId);

            if (res.data == "") this.avatar = null;
            else this.avatar = { imageURL: res.data };
            await this.$functions.delay(5);
            this.saved = true;
            this.saving = false;

            this.$store.commit("user/SET_IMGPROFILO", res.data);
        },

        async uploadImage() {
            this.saving = true;
            //salvo l'immagine del profilo
            await save_immagineAccount(this.getUserId, this.avatar.formData);
            this.saving = false;
            this.saved = true;

            this.caricaAvatar();
        },

        async deleteImage() {
            //cancello l'immagine del profilo
            let res = await del_immagineAccount(this.getUserId);
            if (res.data != "OK") {
                this.$toast.alert("Errore. Impossibile cancellare l'immagine");
                return;
            }
            this.caricaAvatar();
        },
    },

    computed: {
        getUtente() {
            return this.$store.getters["user/getNome"];
        },
        getEmail() {
            return this.$store.getters["user/getEmail"];
        },
        userName() {
            return storage.getUsername();
        },
    }
}
</script>