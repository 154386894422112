import request from '@/services/interceptor.js'

//#region DDT
export const get_infoDocu = (par) => {
    return request({
        url: '/documenti/ddt/info/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const get_data = (par, data) => {
    return request({
        url: '/documenti/' + par,
        method: 'post',
        data: data,
        loadingBar: true
    })
};


export const get_dettaglioDocu = (type, par) => {
    return request({
        url: '/documenti/' + type + "/dettaglio/" + par,
        method: 'get',
        loadingBar: true
    })
};

//ritorna lo stato di modifica del documento (editable, confirmed, error)
export const get_statoDocu = (par) => {
    return request({
        url: '/documenti/ddt/stato/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const salva_documento = (data) => {
    return request({
        url: '/documenti/ddt/salva',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const del_documento = (par) => {
    return request({
        url: '/documenti/ddt/delete/' + par,
        method: 'delete',
        loadingBar: true
    })
};


export const stampa_documento = (data) => {
    return request({
        url: '/documenti/ddt/stampa',
        method: 'post',
        data: data,
        responseType: 'blob',
        loadingBar: true
    })
};

//#endregion

//#region FATTURE


export const get_dati_grafico = (data) => {
    return request({
        url: '/documenti/fatture/grafico',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const get_dati_grafico_tot = (data) => {
    return request({
        url: '/documenti/fatture/totale/grafico',
        method: 'post',
        data: data,
        loadingBar: true
    })
};
//#endregion