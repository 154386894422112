<template>
  <v-app>
    <v-main>
      <v-dialog class="update-dialog" v-model="prompt" persistent width="400">
        <v-card class="ma-0 pa-0">
          <v-card-title class="pl-0 pr-0 ma-0" style="text-align: center; display: flex; align-items: center">
            <span style="width: 100%"> È disponibile una nuova versione</span>
          </v-card-title>
          <v-card-actions class="pl-2">
            <v-spacer />
            <v-btn class="update-dialog__button update-dialog__button--confirm" @click="update">
              Aggiorna
            </v-btn>
            <v-spacer />
            <!-- <v-btn
              class="update-dialog__button update-dialog__button--cancel"
              @click="prompt = false"
            >
              Annulla
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view> </router-view>
    </v-main>

    <!-- Plugin -->
    <toast></toast>
    <confirm></confirm>
    <wait></wait>
  </v-app>
</template>

<script>

import { version } from "../package";
import storage from "./services/storage";

export default {
  name: "App",
  data() {
    return {
      prompt: false,
    };
  },

  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },

  },

  mounted() {
    if (this.getPreferences && this.getPreferences.darkTheme) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },

  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
      });
    }
  },

  computed: {
    getPreferences() {
      return JSON.parse(storage.getPreferences());
    },
  },
};
</script>
