import { Doughnut } from "vue-chartjs";
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Doughnut,

  props: ["data", "options"],

  components: {
    ChartJsPluginDataLabels,
  },

  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.data, this.options);
  }
};
