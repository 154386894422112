<template src="./ddt.html"></template>


<script>
import {
  get_data,
  stampa_documento,
  get_statoDocu,
} from "@/api/api.documenti.js";

import storage from "../../services/storage";
import DialogDocu from "@/components/dialog/documenti/DialogDocu.vue";

export default {
  components: {
    DialogDocu,
  },

  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    search: "",

    elencoDati: [],
    headersDati: [
      { text: "Numero documento", value: "nrDocu" },
      {
        text: "Data documento",
        value: "dataFormat",
        sortable: true,
        sort: (a, b) => {
          return new Date(b) - new Date(a);
        }
      },
      { text: "Posizioni", value: "numRighe" },
      { text: "Cliente", value: "ragSoc" },
      { text: "Numero bolla", value: "nrBolla" },
      { text: "Data bolla", value: "dtBolla" },
      { text: "Qta", value: "qtaTot" },
      { text: "Importo", value: "importoTot" },
      { text: "Azioni", value: "", sortable: false },
    ],

    dialogDocu: false,
    anno: new Date().getFullYear(),
    elencoAnni: [],

    nrDocuSel: "-1",
    clienteSel: "",
  }),

  methods: {
    async caricaDati() {
      let par = {
        anno: this.anno,
      };
      let res = await get_data("ddt/elenco", par);
      this.elencoDati = res.data;
    },

    refresh() {
      this.caricaDati();
    },

    nuovoDocumento() {
      this.nrDocuSel = "-1";
      this.dialogDocu = true;
    },

    chiudiDialog(refresh) {
      this.dialogDocu = false;
      if (refresh) this.refresh();
    },

    editDDT(item) {
      this.nrDocuSel = item.nrDocu.toString();
      this.clienteSel = item.codAnagrafica.toString();
      this.dialogDocu = true;
    },

    async caricaStatoDocu(nrDocu) {
      let res = await get_statoDocu(nrDocu);
      return res.data;
    },

    async stampaDDT(item) {
      let confirm = false;
      if ((await this.caricaStatoDocu(item.nrDocu)) != "editable") {
        confirm = true;
      } else {
        confirm = await this.$confirm.open(
          "Attenzione",
          "Una volta stampato il documento non sarà più possibile modificarlo. Confermare la stampa?"
        );
      }

      if (!confirm) return;
      try {
        let res = await stampa_documento(item);
        console.log(res.data);
        this.$i2kFunctions.createBlobPdf(res.data);
      } catch (error) {
        this.$toast.alert(error);
      }
    },

    caricaAnni() {
      for (let i = 2022; i < this.anno + 1; i++) {
        this.elencoAnni.push(i);
      }
    },
  },

  mounted() {
    this.caricaDati();
    this.caricaAnni();
  },

  computed: {
    role() {
      return storage.getRole();
    },

    userName() {
      return storage.getUsername();
    },
  },
};
</script>