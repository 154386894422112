<template src="./login.html"></template>

<script>
import storage from "../services/storage";

export default {
  data: function () {
    return {
      e1: true,

      cred: {
        username: "",
        password: "",
      },
      attesa: false,

      preferences: {
        darkTheme: false,
      },
    };
  },

  methods: {
    async submit() {
      window.scrollTo(0, 0); // Scroll to top (serve sul mobile per vedere eventuali messaggi)

      // Reset messaggi
      this.$toast.close();

      // Validazione
      if (this.$refs.formLogin.validate() == false) {
        return;
      }

      // Attesa
      this.attesa = true;

      try {
        // Login
        await this.$store.dispatch("auth/login", this.cred);

        // Vado alla pagina riservata
        this.$router.push({ path: "/" });
      } catch (error) {
        this.$toast.alert(this.$functions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    changeTheme() {
      this.preferences.darkTheme = !this.preferences.darkTheme;
      storage.setPreferences(this.preferences);
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },

  mounted() {
    if (this.getPreferences) this.preferences = this.getPreferences;
  },

  computed: {
    getPreferences() {
      return JSON.parse(storage.getPreferences());
    },
  },
};
</script>




