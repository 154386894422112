<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    height="1000"
    @keydown.esc="chiudiDialog()"
    persistent
  >
    <v-card>
      <v-toolbar dense dark>
        <span style="font-size: large; margin-left: 5px"> Info Error </span>
        <v-spacer></v-spacer>
        <v-btn fab color="transparent" elevation="0" @click="chiudiDialog()">
          <v-icon> close </v-icon>
        </v-btn>
      </v-toolbar>

      <v-container>
        <v-textarea
          outlined
          label="Message"
          v-model="info.message"
        ></v-textarea>
        <v-textarea
          outlined
          style="height: 400px"
          rows="13"
          label="StackTrace"
          v-model="info.stackTrace"
        ></v-textarea>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    info: { type: Object, default: null },
  },

  data() {
    return {
      errore: {},
    };
  },

  methods: {
    chiudiDialog() {
      this.$emit("chiudiDialog");
    },
  },

  watch: {
    dialog() {
      this.errore = this.info;
    },
  },

  mounted() {},
};
</script>
