<template class="ml-0 mr-0" src="./dialogDocu.html"></template>

<script>
import { get_clienti } from "@/api/api.calendar.js";
import {
  get_infoDocu,
  get_dettaglioDocu,
  get_statoDocu,
  salva_documento,
  del_documento,
} from "@/api/api.documenti.js";
import { get_infoCliente, get_anagrafica } from "@/api/api.anagrafica.js";

import mixinDocu from "@/views/documenti/mixinDocu.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    nrDocu: { type: String, default: "-1" },
    cdAnagrafica: { type: String, default: "" },
  },

  mixins: [mixinDocu],

  data: () => ({
    //refreshOnClose: false,
    elencoClienti: [],

    calendarData: false,
    calendarDtTrasporto: false,
    pickerOraTrasporto: false,

    datiTestata: {},

    headersDettaglio: [],

    datiDettaglio: [],
    statoDocu: "",

    expanded: [],

    //Dettaglio documento
    elencoTipiRiga: [],
    elencoOmaggi: [],
    elencoControPartite: [],
    elencoUM: [],
    elencoIVA: [],
    elencoArticoli: [],

    //Dati di testata
    elencoAgenti: [],
    elencoPagamenti: [],
    elencoBanche: [],
    elencoDestinazioni: [],

    //Dati di coda - accompagnatori
    elencoTrasporti: [],
    elencoCausali: [],
    elencoModalitaConsegna: [],
    elencoVettori: [],
    elencoAeB: [], //Ana_AspettoEsteriore
    elencoOrdineSA: ["Saldo", "Acconto"],

    editItem: {},

    required: [(v) => !!v || "Campo obbligatorio."],

    valid: true,
    activeTab: 0,
  }),

  methods: {
    async caricaDati() {
      await this.caricaSelect();
      let res = await get_infoDocu(this.nrDocu);
      this.datiTestata = res.data;
      this.normalizzaData();

      if (this.nrDocu != "-1") {
        this.caricaDatiCliente();
        this.caridaDettaglio();
      }
      //carico lo stato del documento ("editable" se posso ancora modificarlo, "confirmed" se è già stato stampato)
      await this.caricaStatoDocu();
      //unisco i dati di default con quelli recuperati dalla chiamata api

      /* this.datiTestata = {
        ...mixinDocu.methods.setDefaultTestata(),
        ...this.datiTestata,
      }; */
      if (this.nrDocu == -1) {
        this.$refs.txt_ragSoc.focus();
      }
    },

    async caricaDatiCliente() {
      if (this.cdAnagrafica == "") return;
      let res = await get_infoCliente(this.cdAnagrafica);

      this.datiTestata.cliente = res.data;
      this.normalizzaData();
    },

    normalizzaData() {
      if (
        this.datiTestata.dtDocu &&
        this.datiTestata.dtDocu.split("-").length < 3
      )
        this.datiTestata.dtDocu = this.$i2kFunctions.formatITDateToISO(
          this.datiTestata.dtDocu.substring(0, 10)
        );
    },

    async caridaDettaglio() {
      let res = await get_dettaglioDocu("ddt", this.nrDocu);
      this.datiDettaglio = res.data;

      if (this.datiTestata.dtTrasporto) {
        this.datiTestata.dtTrasporto = this.datiTestata.dtTrasporto.substring(
          0,
          10
        );
      }
    },

    async caricaStatoDocu() {
      let res = await get_statoDocu(this.datiTestata.nrDocu);
      this.statoDocu = res.data;
    },

    async chiudi(conferma) {
      if (
        conferma &&
        this.statoDocu == "editable" &&
        this.checkCliente(this.datiTestata)
      ) {
        let confirm = await this.$confirm.open(
          "Chiudi",
          "Le modifiche non salvate andranno perse, continuare?"
        );

        if (!confirm) return;
        this.reset();
        this.$emit("chiudi", false);
      } else {
        this.reset();
        this.$emit("chiudi", true);
      }
    },

    async salva() {
      if (!this.checkNumero(this.datiTestata)) return;
      if (!this.checkCliente(this.datiTestata)) {
        this.$toast.alert("Nessun cliente selezionato");
        return;
      }
      if (this.datiDettaglio.length <= 0) {
        this.$toast.alert("Inserire almeno una posizione");
        this.activeTab = 0;
        return;
      }
      if (!this.checkDatiTestata(this.datiTestata)) return;

      this.datiTestata.ragSoc = this.datiTestata.cliente.codAnagrafica;
      let par = {
        testata: this.datiTestata,
        posizioni: this.datiDettaglio,
      };

      let res = await salva_documento(par);
      if (res.data == "OK") this.chiudi(false);
      else {
        this.$toast.alert(res.data);
        return;
      }
    },

    async eliminaDDT() {
      if (this.statoDocu != "editable") return;
      let confirm = await this.$confirm.open(
        "Elimina",
        "Il documento e tutte le posizioni verranno eliminati definitivamente e non sarà più possibile recuperarlo. Continuare?"
      );

      if (!confirm) return;
      let res = await del_documento(this.nrDocu);
      if (res == "OK") this.chiudi();
      else {
        this.$toast.alert(res.data);
        return;
      }
    },

    async caricaClienti() {
      let res = await get_clienti();
      this.elencoClienti = res.data;
    },

    salvaPagamentoCli() {
      if (this.datiTestata.cliente == null) {
        this.datiTestata.cdPagamento = "";
        this.datiTestata.bancaAzienda = "";
      } else {
        this.datiTestata.cdPagamento = this.datiTestata.cliente.codPagamento;
        this.datiTestata.bancaAzienda = this.datiTestata.cliente.bancaDitta;
      }
    },

    aggiungiPosizione() {
      if (!this.checkNumero(this.datiTestata)) return;
      if (!this.checkCliente(this.datiTestata)) {
        this.$toast.alert("Nessun cliente selezionato");
        return;
      }
      if (
        this.statoDocu != "confirmed" &&
        this.editItem.expanded &&
        !this.editItem.salvato
      ) {
        this.$toast.alert("Prima di chiudere salvare la posizione");
        return;
      }

      this.activeTab = 0;

      //aggiungo il primo elemento nella lista
      let elem = {};
      let riga = 0;
      if (this.datiDettaglio.length > 0) {
        //recupero il numero massimo delle posizioni presenti nella lista
        riga = Math.max(...this.datiDettaglio.map((o) => o.nrRigaDocu));
      }
      //dati di default
      elem = {
        nrRigaDocu: riga + 1,
        expanded: true,
        tpRiga: "PRODOTTO",
        tpOmaggio: "Normale        ",
        controPartita: "0100000",
      };

      this.datiDettaglio.push(elem);
      this.expanded = [];
      this.expanded.push(elem);
      this.editItem = elem;
    },

    async eliminaPosizione() {
      let confirm = await this.$confirm.open(
        "Elimina",
        "La posizione corrente verrà eliminata definitivamente. Continuare?"
      );

      if (!confirm) return;
      this.datiDettaglio = this.datiDettaglio.filter(
        (x) => x.nrRigaDocu != this.editItem.nrRigaDocu
      );
      this.expanded = [];
      this.editItem = {};
    },

    salvaPosizione() {
      if (!this.$refs.formDettaglioDocu.validate()) {
        this.$toast.alert(
          "Prima di salvare compilare tutti i campi obbligatori."
        );
        return;
      }
      if (
        this.editItem.sconto1 == null ||
        this.editItem.sconto1 == undefined ||
        this.editItem.sconto1 == "" ||
        this.editItem.sconto1 < 0
      )
        this.editItem.sconto1 = 0;
      if (
        this.editItem.sconto2 == null ||
        this.editItem.sconto2 == undefined ||
        this.editItem.sconto2 == "" ||
        this.editItem.sconto2 < 0
      )
        this.editItem.sconto2 = 0;

      this.editItem.salvato = true;
      this.editItem.expanded = false;
      this.expanded = [];
      this.editItem = {};

      this.aggiornaTotale();
    },

    aggiornaTotale() {
      let tot = 0;
      this.datiDettaglio.forEach((el) => {
        tot += Number(el.impRigo);
      });
      this.datiTestata.importoTot = Number(tot).toFixed(2);
    },

    async caricaSelect() {
      let res = null;
      //Dettaglio documento
      res = await get_anagrafica("tipiriga");
      this.elencoTipiRiga = res.data;

      res = await get_anagrafica("omaggi");
      this.elencoOmaggi = res.data;

      res = await get_anagrafica("contropartite");
      this.elencoControPartite = res.data;

      res = await get_anagrafica("um");
      this.elencoUM = res.data;

      res = await get_anagrafica("iva");
      this.elencoIVA = res.data;

      res = await get_anagrafica("articoli");
      this.elencoArticoli = res.data;

      //Dati di testata
      res = await get_anagrafica("pagamenti");
      this.elencoPagamenti = res.data;

      res = await get_anagrafica("banche");
      this.elencoBanche = res.data;

      //Dati di coda - accompagnatori
      res = await get_anagrafica("trasporti");
      this.elencoTrasporti = res.data;

      res = await get_anagrafica("causali");
      this.elencoCausali = res.data;

      res = await get_anagrafica("modalitaconsegna");
      this.elencoModalitaConsegna = res.data;

      res = await get_anagrafica("vettori");
      this.elencoVettori = res.data;

      res = await get_anagrafica("aspettoesteriore");
      this.elencoAeB = res.data;
    },

    expandItem(item) {
      this.datiDettaglio.forEach((el) => {
        if (el != item) el.expanded = false;
      });

      if (
        this.statoDocu != "confirmed" &&
        item.expanded &&
        !this.editItem.salvato
      ) {
        this.$toast.alert("Prima di chiudere salvare la posizione corrente!");
        return;
      }
      item.expanded = !item.expanded;
      this.expanded = [];
      if (item.expanded) {
        this.expanded.push(item);
        this.editItem = item;
      } else this.editItem = {};
    },

    disabilita(elem) {
      //se true disabilita o rende readonly il controllo
      switch (elem) {
        case "salva":
          return (
            this.statoDocu == "confirmed" ||
            (this.editItem != {} &&
              this.editItem != null &&
              this.editItem.nrRigaDocu != undefined)
          );
        case "annulla":
          return (
            this.editItem != {} &&
            this.editItem != null &&
            this.editItem.nrRigaDocu != undefined
          );
        case "posizione":
          return this.statoDocu == "confirmed";

        default:
          break;
      }
    },

    oraAdesso() {
      this.datiTestata.dtTrasporto = "";
      this.datiTestata.oraTrasporto = "";
      let time = new Date();
      this.datiTestata.dtTrasporto = time.toISOString().substring(0, 10);
      this.datiTestata.dtTrasportoIT = this.$i2kFunctions.formatISODate(
        this.datiTestata.dtTrasporto
      );
      this.datiTestata.oraTrasporto = time.getHours() + ":" + time.getMinutes();
    },

    //#region controlli input
    checkCliente(datiTestata) {
      if (
        datiTestata.cliente == {} ||
        datiTestata.cliente == null ||
        datiTestata.cliente.codAnagrafica == undefined ||
        datiTestata.cliente.codAnagrafica == ""
      ) {
        return false;
      }
      return true;
    },

    checkNumero(datiTestata) {
      if (datiTestata.nrDocu == 0) {
        this.$toast.alert("Nessun documento selezionato");
        return false;
      }
      return true;
    },

    checkDatiTestata(datiTestata) {
      if (datiTestata.cdPagamento == null) datiTestata.cdPagamento = "";

      if (datiTestata.bancaAzienda == null || datiTestata.bancaAzienda == "") {
        this.$toast.alert(
          "Banca azienda non impostata nella sezione 'Dati testata'"
        );
        this.activeTab = 1;
        return false;
      }
      //dati di coda-accompagnatori (facoltativi)
      /* if (
        datiTestata.causaleConsegna == null ||
        datiTestata.causaleConsegna == ""
      ) {
        this.$toast.alert(
          "Causale di consegna non impostata nella sezione 'Dati di coda'"
        );
        this.activeTab = 2;
        return false;
      }
      if (datiTestata.codiceAeB == null || datiTestata.codiceAeB == "") {
        this.$toast.alert(
          "Codice AeB non impostato nella sezione 'Dati di coda'"
        );
        this.activeTab = 2;
        return false;
      }
      if (datiTestata.dtTrasporto == null || datiTestata.dtTrasporto == "") {
        this.$toast.alert(
          "Data di trasporto non impostata nella sezione 'Dati di coda'"
        );
        this.activeTab = 2;
        return false;
      } */
      return true;
    },

    reset() {
      this.datiTestata = {};
      this.datiDettaglio = [];
      this.editItem = {};
      this.expanded = [];
    },
    //#endregion

    //#region filtri select
    filterPagamento(item, queryText) {
      return (
        item.codPagamento.toLowerCase().includes(queryText.toLowerCase()) ||
        item.descrizione.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    filterBanca(item, queryText) {
      return (
        item.codBanca.toLowerCase().includes(queryText.toLowerCase()) ||
        item.descrizione.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    filterArticolo(item, queryText) {
      return (
        item.codArticolo.toLowerCase().includes(queryText.toLowerCase()) ||
        item.descrizione.toLowerCase().includes(queryText.toLowerCase())
      );
    },

    //#endregion
  },

  mounted() {
    this.caricaClienti();
  },

  created() {
    this.headersDettaglio = this.getHeadersDettaglio();
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.datiDettaglio = [];
        this.caricaDati();
      }
    },

    "datiTestata.dtDocu": function () {
      let dtTemp = this.$i2kFunctions.formatISODate(this.datiTestata.dtDocu);
      if (dtTemp != null && !dtTemp.toString().includes("undefined"))
        this.datiTestata.dtDocuIT = dtTemp;
      else this.datiTestata.dtDocuIT = this.datiTestata.dtDocu;
    },

    "datiTestata.dtTrasporto": function () {
      this.datiTestata.dtTrasportoIT = this.$i2kFunctions.formatISODate(
        this.datiTestata.dtTrasporto
      );
    },

    "editItem.cdArticoloPadre": function (newVal) {
      if (newVal != null && newVal != "") {
        let articoloSel = this.elencoArticoli.filter(
          (x) => x.codArticolo === newVal
        );
        this.editItem.descrizioneProdotto = articoloSel[0].descrizione;
        this.editItem.prUnitario = articoloSel[0].prezzo1;
        this.editItem.cdIva = articoloSel[0].codIva;
        if (this.editItem.qtOrdinata != null && this.editItem.qtOrdinata != "")
          this.editItem.impRigo = Number(
            this.editItem.qtOrdinata * this.editItem.prUnitario
          ).toFixed(2);
      }
    },

    //calcolo importo rigo
    "editItem.qtOrdinata": function () {
      if (
        this.editItem.cdArticoloPadre != null &&
        this.editItem.cdArticoloPadre != ""
      ) {
        if (!isNaN(this.editItem.prUnitario))
          this.editItem.impRigo = Number(
            this.editItem.qtOrdinata * this.editItem.prUnitario
          ).toFixed(2);
      }
    },

    //descrizione codice pagamento
    "datiTestata.cdPagamento": function () {
      this.datiTestata.descrPagamento = "";
      if (this.datiTestata.cdPagamento && this.elencoPagamenti.length > 0) {
        this.datiTestata.descrPagamento = this.elencoPagamenti.filter((x) => {
          return x.codPagamento == this.datiTestata.cdPagamento;
        })[0].descrizione;
        //this.datiTestata.cdPagamento = this.datiTestata.cdPagamento.toString();
      }
    },

    //iban banca azienda
    "datiTestata.bancaAzienda": function () {
      this.datiTestata.descrBancaAzienda = "";
      this.datiTestata.ibanAzienda = "";
      if (this.datiTestata.bancaAzienda && this.elencoBanche.length > 0) {
        let val = this.elencoBanche.filter((x) => {
          return x.codBanca == this.datiTestata.bancaAzienda;
        })[0];
        if (!val) return;
        this.datiTestata.ibanAzienda = val.iban;
        this.datiTestata.descrBancaAzienda = val.descrizione;
      }
    },

    //descrizione A e B (aspetto esteriore)
    "datiTestata.codiceAeB": function () {
      if (this.datiTestata.codiceAeB && this.elencoAeB.length > 0)
        this.datiTestata.descrizioneAeB = this.elencoAeB.filter((x) => {
          return x.codice == this.datiTestata.codiceAeB;
        })[0].descrizione;
    },

    //descrizione causale consegna
    "datiTestata.causaleConsegna": function () {
      if (this.datiTestata.causaleConsegna && this.elencoCausali.length > 0)
        this.datiTestata.descrizioneConsegna = this.elencoCausali.filter(
          (x) => {
            return x.codice == this.datiTestata.causaleConsegna;
          }
        )[0].descrizione;
    },
  },
};
</script>