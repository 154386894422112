import Vue from 'vue'
import Router from 'vue-router'

import Main from '@/views/Main.vue'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import Calendar from '@/views/Calendar.vue'
import Preferences from '@/views/Preferences.vue'

//Documenti
import Ddt from "@/views/documenti/DDT.vue"
import Fatture from "@/views/documenti/Fatture.vue"

import Riepilogo from "@/views/clienti/RiepilogoOre"
import Anagrafica from "@/views/anagrafica/Anagrafica.vue"

//Tools
import Strumenti from "@/views/tools/Strumenti.vue"
import Errors from "@/views/tools/Errors.vue"
//import Comunicazioni from "@/views/tools/Comunicazioni.vue"
//import Archivio from "@/views/archivio/Archivio.vue"

import Profilo from "@/views/user/Profilo.vue"

import storage from '@/services/storage.js'

Vue.use(Router)

const routerOptions = [{
  path: '/',
  component: Main,
  meta: {
    checkAuth: true
  },
  children: [{
    path: '/',
    component: Home,
    meta: {
      checkAuth: true
    },
  },
  {
    path: '/calendar',
    component: Calendar,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/preferences',
    component: Preferences,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/documenti',
    component: Ddt,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/fatture',
    component: Fatture,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/riepilogo',
    component: Riepilogo,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/anagrafica',
    component: Anagrafica,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/strumenti',
    component: Strumenti,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/errors',
    component: Errors,
    meta: {
      checkAuth: true
    }
  },
  /*  {
     path: '/comunicazioni',
     component: Comunicazioni,
     meta: {
       checkAuth: true
     }
   }, */
  {
    path: '/profilo',
    component: Profilo,
    meta: {
      checkAuth: true
    }
  },
    /* {
      path: '/archivio',
      component: Archivio,
      meta: {
        checkAuth: true
      }
    }, */
  ]
},
{
  path: '/login',
  component: Login,
},
{
  path: "*", // Pagine non trovate (redirect su /)
  beforeEnter: (to, from, next) => {
    next({
      path: '/'
    })
  }
}
]


const rou = new Router({
  mode: 'history',
  routes: routerOptions
})


rou.beforeEach((to, from, next) => {

  // Controllo se è richiesta l'autenticazione
  if (to.meta.checkAuth && !storage.checkAuth()) {

    next({
      path: '/login'
    })

    return

  }
  // Controllo pagina login
  else if (to.path == "/login" && storage.checkAuth()) {

    // Se il login è stato fatto, vado alla pagina riservata
    next({
      path: '/'
    })
    console.log("app created")
    return
  }

  next();

})



export default rou;