
const app = {
    namespaced: true,

    state: {
        loading: false,
    },

    mutations: {

        SET_LOADING(state, vis) {
            state.loading = vis
        }

    },


}

export default app