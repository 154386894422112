import request from '@/services/interceptor.js'


export const get_utenti = () => {
    return request({
        url: '/anagrafica/utenti',
        method: 'get',
        loadingBar: true
    })
};

export const get_clienti = () => {
    return request({
        url: '/anagrafica/clienti',
        method: 'get',
        loadingBar: true
    })
};


export const get_infoCliente = (par) => {
    return request({
        url: '/anagrafica/clienti/info/' + par,
        method: 'get',
        loadingBar: true
    })
};


export const get_anagrafica = (par) => {
    return request({
        url: '/anagrafica/' + par,
        method: 'get',
        loadingBar: true
    })
};