<template src="./home.html">

</template>

<script>
export default {
  methods: {
    async init() {
      let resUpdate = await this.$i2kFunctions.checkUpdate(
        this.$load.getUrlUpdate()
      );

      if (resUpdate) this.$toast.update("E' disponibile una nuova versione");
    },
  },

  mounted() {
    this.init();
  },
};
</script>

