export default {
    methods: {
        getHeadersUtenti() {
            return ([{
                text: "Nome utente",
                value: "username"
            },
            {
                text: "E-Mail",
                value: "email"
            },
            {
                text: "Ruolo",
                value: "role"
            },
            {
                text: "Data ultimo login",
                value: "dateLogin"
            },
            {
                text: "Azioni",
                value: ""
            },
            ])
        },

        getHeadersClienti() {
            return ([{
                text: "codice",
                value: "codAnagrafica"
            },
            {
                text: "ragione sociale",
                value: "ragSoc"
            },
            {
                text: "Localita",
                value: "localita"
            },
            {
                text: "Indirizzo",
                value: "indirizzo",
                sortable: false
            },
            {
                text: "P. IVA",
                value: "piva",
                sortable: false
            },
            {
                text: "Telefono",
                value: "telefono",
                sortable: false
            },
            {
                text: "Colore",
                value: "colore",
                sortable: false
            }
            ])
        },
        getHeadersMenu() {
            return [{
                text: "Nome",
                value: "menuName"
            }, {
                text: "Testo",
                value: "text"
            },
            /*  {
                            text: "Role",
                            value: "role"
                        },  */
            {
                text: "Tipologia",
                value: "type"
            },
            /*  {
                                text: "Nascosto",
                                value: "hidden"
                            }, */
            {
                text: "Icona",
                value: "img"
            }, {
                text: "Link",
                value: "link"
            }, {
                text: "Colore",
                value: "color"
            }, {
                text: "Progressivo",
                value: "progOrder"
            }, {
                text: "Azioni",
                value: ""
            },
            ]
        },

        getHeadersArticoli() {
            return [
                {
                    text: "CodArticolo",
                    value: "codArticolo"
                },
                {
                    text: "Descrizione",
                    value: "descrizione"
                },
                {
                    text: "Descrizione2",
                    value: "descrizione2"
                }]
        }
    }
}