var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-col',[_c('v-card',{staticClass:"mb-5",attrs:{"width":"100%","height":"4em"}},[_c('v-row',{staticClass:"ma-1",attrs:{"margin-bottom":"0px"}},[_c('div',[_c('v-btn',{staticClass:"mt-3",staticStyle:{"margin-right":"0px"},attrs:{"text":"","fab":"","small":""},on:{"click":function($event){return _vm.caricaDati()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("refresh")])],1)],1),_c('div',[_c('v-flex',{staticStyle:{"width":"7em"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-3 mt-5",attrs:{"disabled":_vm.loadingTable,"color":"primary","label":"Mese","prepend-icon":"event","readonly":"","dense":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"primary","type":"month","first-day-of-week":1,"disabled":_vm.loadingTable,"locale":"it-it"},on:{"input":function($event){_vm.menu = false; _vm.caricaDati()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('v-spacer'),_c('div',[_c('v-text-field',{staticStyle:{"width":"500px !important"},attrs:{"color":"primary","append-icon":"search","label":"Cerca"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-row',{staticClass:"mb-10",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"margin-bottom":"4em"},attrs:{"loading":_vm.loadingTable,"loading-text":"Caricamento dati in corso...","no-data-text":"Nessun dato disponibile","headers":_vm.headerTabella,"mobile-breakpoint":"0","items":_vm.errorList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"fixed-header":"","dense":"","hide-default-header":""},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(head,i){return _c('th',{key:i},[_c('span',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"dblclick":function($event){$event.stopPropagation();return _vm.apriDialog(item)}}},[_c('td',[_vm._v(_vm._s(item.userID))]),_c('td',[_vm._v(_vm._s(_vm.parseData(item.dateOccur)))]),_c('td',[_vm._v(_vm._s(item.error))]),_c('td',[_vm._v(_vm._s(item.modelFunction))]),_c('td',[_vm._v(_vm._s(item.debug))]),_c('td',[_c('v-btn',{attrs:{"text":"","fab":"","small":"","disabled":_vm.loadingTable},on:{"click":function($event){return _vm.apriDialog(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("info")])],1)],1)])]}}])})],1)],1)],1),_c('dialog-error',{attrs:{"dialog":_vm.dialog,"info":_vm.info},on:{"chiudiDialog":_vm.chiudiDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }