<template>
    <v-dialog v-model="dialog" persistent id="dialog-chart" @keydown.esc="chiudi()">
        <v-card>
            <v-card-title>
                <v-autocomplete class="mb-2 pb-1 ml-2" hide-details label="Cliente" v-model="filters.cdContatto" clearable
                    no-data-text="Nessun dato disponibile" :items="elencoClienti" item-text="ragSoc"
                    item-value="codAnagrafica" @input="caricaDati()" />

                <v-spacer />
                <v-btn icon @click="chiudi()"><v-icon>close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text id="card-charts">
                <v-row class="ma-0 pa-0 desktop">
                    <v-col cols="6" class="ma-0 pa-0">
                        <!-- {{ dati.datasets[0] }} -->
                        <bar-chart :data="dati" :options="options" :key="key" style="cursor: pointer;" />
                        <!-- <div id="main" style="height:400px;"></div> -->
                    </v-col>
                    <!-- <v-spacer /> -->
                    <v-col cols="4" class="ma-0 pa-0 mt-0 pt-0">
                        <v-row class="ma-0 pa-0">
                            <v-spacer />
                            <div style="font-size: 1.2em;">
                                <strong>
                                    {{ filters.anno }}
                                    <span v-if="filters.cdContatto != null && filters.cdContatto != ''">
                                        {{ percValue }} &#x25;
                                    </span>
                                </strong>
                            </div>
                            <v-spacer />
                        </v-row>
                        <doughnut-chart :data="datiTot" :options="optionsTot" :key="keyt" />
                        <!-- <div id="main2" style="height:400px;"></div> -->
                    </v-col>
                    <v-col class="ma-0 pa-0"
                        style="font-size: 0.8em; display: flex; align-items: center; justify-content: center;">
                        <v-container fluid class="ma-0 pa-0">
                            <v-row class="ma-0 pa-0 mb-2" v-for="(item, i) in datiTot.labels" :key="i">
                                <v-chip small :color="datiTot.datasets[0].backgroundColor[i]" />
                                {{ item }}
                                <v-spacer />
                                &euro; {{ datiTot.datasets[0].data[i].toLocaleString('en') }}
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>

                <v-row class="ma-0 pa-0 mobile">
                    <v-row class="ma-0 pa-0">
                        <bar-chart :data="dati" :options="options" :key="key"
                            style="max-height: 300px; max-width: 86vw !important;" />
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-row class="ma-0 pa-0">
                            <v-spacer />
                            <div style="font-size: 1.2em;">
                                <strong>
                                    {{ filters.anno }}:
                                    <span v-if="filters.cdContatto != null && filters.cdContatto != ''">
                                        {{ percValue }} &#x25;
                                    </span>
                                </strong>
                            </div>
                            <v-spacer />
                        </v-row>
                        <doughnut-chart :data="datiTot" :options="optionsTot" :key="keyt"
                            style="max-height: 300px; max-width: 86vw !important;" />
                    </v-row>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
//import * as echarts from 'echarts';
import {
    get_dati_grafico,
    get_dati_grafico_tot,
} from "@/api/api.documenti.js"

import BarChart from "@/components/chart/BarChart.js";
import DoughnutChart from "@/components/chart/DoughnutChart.js";

export default {
    props: {
        dialog: { type: Boolean, default: false },
        filtri: { type: Object, default: null },
        elencoClienti: { type: Array, default: () => [] }
    },

    components: {
        BarChart, DoughnutChart,
    },

    data: () => ({
        filters: {},
        dati: {},
        datiTot: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                labels: {
                    //fontColor: '#FF0000'
                }
            },
            animation: {
                duration: 1000,
            },
            plugins: {
                datalabels: {
                    color: "#D7D7D7",
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        },
        optionsTot: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            animation: {
                duration: 0,
            },
            plugins: {
                datalabels: {
                    color: "#000",
                }
            }
        },
        loaded: false,
        key: 0, keyt: 0,
        percValue: 0,
    }),

    methods: {
        chiudi() {
            this.$emit("chiudi");
        },

        async caricaDati() {
            this.dati = {};
            let res = await get_dati_grafico(this.filters);
            this.dati = res.data;
            this.key += 1;
            this.caricaDatiTot();


            /* let chartDom = document.getElementById('main');
            let myChart = echarts.init(chartDom, 'dark');
            window.addEventListener('resize', function () {
                myChart.resize();
            });
            let option = {
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            };

            myChart.setOption(option);


            let chartDom2 = document.getElementById('main2');
            let myChart2 = echarts.init(chartDom2, 'dark');
            window.addEventListener('resize', function () {
                myChart2.resize();
            });
            let option2 = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 40, name: 'rose 1' },
                            { value: 38, name: 'rose 2' },
                            { value: 32, name: 'rose 3' },
                            { value: 30, name: 'rose 4' },
                            { value: 28, name: 'rose 5' },
                            { value: 26, name: 'rose 6' },
                            { value: 22, name: 'rose 7' },
                            { value: 18, name: 'rose 8' }
                        ]
                    }
                ]
            };

            console.log(this.datiTot)
            console.log(this.dati)
            myChart2.setOption(option2); */
        },

        async caricaDatiTot() {
            let res = await get_dati_grafico_tot(this.filters);
            this.datiTot = res.data;
            this.keyt += 1;
            if (this.filters.cdContatto != null && this.filters.cdContatto != "")
                if (this.datiTot.datasets[0].data[0] != 0)
                    this.percValue = (100 * this.datiTot.datasets[0].data[1] / this.datiTot.datasets[0].data[0]).toFixed(2);
                else
                    this.percValue = 0
        },

        handleChartClick(event, elements) {
            if (elements[0] == undefined) return;
            if (event.srcElement.id != "bar-chart") return;
            this.filters.anno = this.dati.labels[elements[0]._index];
            this.caricaDatiTot();
        },
    },

    mounted() {
        this.options.onClick = this.handleChartClick;
    },

    watch: {
        dialog() {
            if (this.dialog) {
                this.filters.anno = this.filtri.anno;
                this.filters.cdContatto = this.filtri.cdContatto;
                this.caricaDati();
            }
        },
    }
}


</script>


<!-- /* https://www.chartjs.org/docs/2.8.0/ */ -->