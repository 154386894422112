import request from '@/services/interceptor.js'


export const get_oreClienti = (par, data) => {
    return request({
        url: '/clienti/ore/' + par,
        method: 'post',
        data: data,
        loadingBar: true
    })
};