<template>
  <v-app-bar app :clipped-left="$vuetify.breakpoint.mdAndUp" fixed style="background-color: #e0e0e0">
    <v-app-bar-nav-icon @click.stop="leftBar()" light />

    <!-- Logo -->
    <v-img :src="require('@/assets/logo.png')" v-on:click="$router.push('/')" style="cursor: pointer" position="left"
      contain width="95" height="50" />

    <v-spacer />

    <!-- Utente -->
    <v-toolbar-title style="font-size: 16px; font-weight: normal; color: #212121">
      {{ utente }}
    </v-toolbar-title>

    <!-- Menu utente -->
    <v-menu offset-y :close-on-click="true" :close-on-content-click="true" :nudge-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-avatar v-if="imgProfilo">
            <img :src="imgProfilo" style="max-width: 35px; max-height: 35px" />
          </v-avatar>
          <v-icon v-else large style="color: #212121"> account_circle </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <!-- Immagine profilo utente -->
              <img v-if="imgProfilo" :src="imgProfilo" style="max-width: 60px; max-height: 60px" />
              <v-icon v-else :x-large="true">account_circle </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Utente :</v-list-item-title>
              <v-list-item-subtitle>{{ utente }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-card-actions>
          <v-btn :disabled="disabilitaBtn" small v-on:click="account"><v-icon center dark>person</v-icon>profilo</v-btn>
          <v-spacer />
          <v-btn :disabled="disabilitaBtn" v-on:click="esci" small>
            <v-icon center>exit_to_app</v-icon>Esci
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>



<script>
export default {
  props: {
    utente: { type: String, default: "" },
  },

  data: () => ({
    disabilitaBtn: false,
  }),

  methods: {
    esci() {
      this.disabilitaBtn = true;

      this.$emit("esci");
    },

    leftBar() {
      this.$emit("showLeftBar");
    },

    account() {
      this.$router.push({ path: "/profilo" });
    },
  },

  computed: {
    imgProfilo() {
      return this.$store.getters["user/getImgProfilo"] || "";
    },
  }
};
</script>