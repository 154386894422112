<template src="./calendar.html"></template>

<script>
import { get_calendar, get_clienti, get_utenti } from "@/api/api.calendar.js";
import dialogCalendario from "@/components/dialog/calendar/CalendarItem";
import DialogEsporta from "@/components/dialog/calendar/Esporta";

import Holidays from "date-holidays";
import storage from "@/services/storage.js";

export default {
  components: {
    dialogCalendario,
    DialogEsporta,
  },

  data: () => ({
    today: new Date().toISOString().substr(0, 10),

    events: [],
    eventoSel: {},

    clienti: [],

    search: "",
    filtriUtenti: [],
    filtroUtenteSel: "",

    dialogIns: false,
    dialogEsporta: false,
  }),

  methods: {
    async caricaDati() {
      let par = this.today + "/";
      par += this.filtroUtenteSel || "";

      try {
        // Calendario
        let res = await get_calendar(par);

        let dati = res.data;
        // Rimuovo l'ora dalla data
        dati.forEach((element) => {
          element.data = element.data.substring(0, 10);
        });

        this.events = dati;
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$functions.getErrorMsg(error));
      }
    },

    apriDialog(event) {
      // Controllo nuovo inserimento
      if (event) {
        // Modifica
        if (event.festa) return;
        this.eventoSel = event;
      } else {
        // Controllo presenza utente
        if (!!this.filtroUtenteSel == false) {
          this.$toast.info("Utente non selezionato");
          return;
        }

        // Nuovo
        this.eventoSel = {
          data: this.today,
          prog: -1,
          utente: this.filtroUtenteSel,
        };
      }

      this.dialogIns = true;
    },

    chiudiDialog(aggiorna) {
      this.dialogIns = false;

      if (aggiorna) this.caricaDati();
    },

    async caricaUtenti() {
      this.filtroUtenteSel = this.userName;
      if (this.role != "admin") return;

      let res = await get_utenti();
      this.filtriUtenti = res.data;
    },

    async caricaClienti() {
      // Clienti (servono per la dialog di inserimento, li carico una volta sola)
      let res = await get_clienti();
      this.clienti = res.data;
    },

    async refresh() {
      await this.caricaClienti();

      await this.caricaDati();
    },

    esportaExcel() {
      this.dialogEsporta = true;
    },

    chiudiEsporta() {
      this.dialogEsporta = false;
    },
  },

  mounted() {
    // Imposto l'utente
    this.caricaUtenti();

    this.refresh();
  },

  watch: {
    today() {
      this.caricaDati();
    },
  },

  computed: {
    // convert the list of events into a map of lists keyed by date
    eventsMap() {
      const map = {};
      let swapArr = this.events;

      // Ricerca
      if (this.search) {
        swapArr = swapArr.filter((f) =>
          f.ragSoc.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      let festivita = [];
      let anno = this.today.substring(0, 4);
      let hd = new Holidays("IT");
      let feste = hd
        .getHolidays(anno)
        .filter((f) => f.type.toLowerCase() == "public");

      feste.forEach((ff) => {
        festivita.push({
          data: ff.date.toString().substring(0, 10),
          ragSoc: ff.name,
          festa: true,
        });
      });

      festivita.forEach((e) => (map[e.data] = map[e.data] || []).push(e));

      swapArr.forEach((e) => (map[e.data] = map[e.data] || []).push(e));

      return map;
    },

    role() {
      return storage.getRole();
    },

    userName() {
      return storage.getUsername();
    },
  },
};
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: var(--primary);
  color: #ffffff;
  border: 1px solid var(--primary);
  width: 100%;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
}

#pulsantiAzione {
  position: fixed;
  right: 1.2em;
  bottom: 1em;
}
</style>
