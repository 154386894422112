<template src="./preferences.html"></template>


<script>
import { version } from "../../package";
import storage from "@/services/storage.js";

export default {
  data: () => ({
    preferences: {
      darkTheme: false,
    },

    menuSel: "",
  }),

  methods: {
    changeTheme() {
      storage.setPreferences(this.preferences);
      if (this.preferences.darkTheme) this.$vuetify.theme.dark = true;
      else this.$vuetify.theme.dark = false;
    },

    setMenuDefault() {
      storage.setMenuDefault(this.menuSel);
    },
  },

  mounted() {
    if (this.getPreferences) this.preferences = this.getPreferences;
    if (this.getMenuDefault) this.menuSel = this.getMenuDefault;
  },

  computed: {
    getPreferences() {
      return JSON.parse(storage.getPreferences());
    },

    version() {
      return version;
    },

    getMenuDefault() {
      return JSON.parse(storage.getMenuDefault());
    },

    elencoMenu() {
      return this.$store.getters["user/getMenu"];
    },
  },
};
</script>