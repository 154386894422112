import request from '@/services/interceptor.js'

// Auth
export const login_user = (par) => {
    return request({
        url: '/auth/login',
        method: 'post',
        data: par,
        loadingBar: true
    })
}
export const logout_user = (par) => {
    return request({
        url: '/auth/logout',
        method: 'post',
        data: par,
        loadingBar: true
    })
}
export const refresh_token = (par) => {
    return request({
        url: '/auth/refreshtoken',
        method: 'post',
        data: par,
        loadingBar: true
    })
}


