var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-card',{staticClass:"pa-0 mb-2 elevation-1 desktop",staticStyle:{"border-radius":"5px"},attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-0 pl-0 pr-0"},[_c('v-row',{staticClass:"pa-0 ml-2 mr-2 mt-0 mb-0"},[_c('v-btn',{staticClass:"mr-2 mt-4",attrs:{"icon":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-btn',{staticClass:"mr-2 mt-4",attrs:{"icon":""},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v("add")])],1),_c('v-text-field',{staticClass:"ml-1",attrs:{"label":"Cerca","hide-details":"","append-icon":"search","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(_vm.role=='admin')?_c('v-tab',{key:1},[_vm._v(" Utenti ")]):_vm._e(),(_vm.role=='admin')?_c('v-tab',{key:2},[_vm._v(" Menu ")]):_vm._e(),_c('v-tab',{key:3},[_vm._v(" Articoli ")]),_c('v-tab',{key:4},[_vm._v(" Clienti ")])],1),_c('v-tabs-items',{attrs:{"value":_vm.activeTab,"touchless":""}},[(_vm.role=='admin')?_c('v-tab-item',{key:1},[_c('v-col',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"items":_vm.elencoDati,"headers":_vm.headersDati,"no-data-text":"Nessun dato disponibile","mobile-breakpoint":0,"items-per-page":100,"hide-default-footer":"","search":_vm.search,"height":"72vh"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.username))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.email))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.role))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.dateLogin))]),_c('td',{staticClass:"text-xs-right"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.modifica(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)],1)])]}}],null,false,2763109701)})],1)],1):_vm._e(),(_vm.role=='admin')?_c('v-tab-item',{key:2},[_c('v-col',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"items":_vm.elencoDati,"headers":_vm.headersDati,"no-data-text":"Nessun dato disponibile","mobile-breakpoint":0,"items-per-page":100,"hide-default-footer":"","search":_vm.search,"height":"72vh"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.menuName))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.text))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.type))]),_c('td',{staticClass:"text-xs-right"},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.img))])],1),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.link))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.color))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.progOrder))]),_c('td',{staticClass:"text-xs-right"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.modifica(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)],1)])]}}],null,false,1197765812)})],1)],1):_vm._e(),_c('v-tab-item',{key:3},[_c('v-col',{staticClass:"pa-0"},[_c('v-switch',{staticClass:"ml-2 mt-1 pb-1",attrs:{"label":"Mostra prezzi","hide-details":"","dense":""},on:{"change":function($event){return _vm.visPrezzi()}},model:{value:(_vm.mostraPrezzi),callback:function ($$v) {_vm.mostraPrezzi=$$v},expression:"mostraPrezzi"}}),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.elencoDati,"headers":_vm.headersDati,"no-data-text":"Nessun dato disponibile","mobile-breakpoint":0,"items-per-page":100,"hide-default-footer":"","search":_vm.search,"height":"65vh"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.codArticolo))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.descrizione))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.descrizione2))]),(_vm.mostraPrezzi)?_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.prezzo1))]):_vm._e(),(_vm.mostraPrezzi)?_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.codIva))]):_vm._e()])]}}])})],1)],1),_c('v-tab-item',{key:4},[_c('v-col',{staticClass:"pa-0"},[_c('v-switch',{staticClass:"ml-2 mt-1 pb-1",attrs:{"label":"Mostra importi","hide-details":"","dense":""},on:{"change":function($event){return _vm.visPrezzi()}},model:{value:(_vm.mostraPrezzi),callback:function ($$v) {_vm.mostraPrezzi=$$v},expression:"mostraPrezzi"}}),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.elencoDati,"headers":_vm.headersDati,"no-data-text":"Nessun dato disponibile","mobile-breakpoint":0,"items-per-page":100,"search":_vm.search,"hide-default-footer":"","height":"65vh"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.codAnagrafica))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.ragSoc))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.localita))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.indirizzo))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.piva))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.telefono))]),_c('td',{staticClass:"text-xs-right"},[_c('v-chip',{attrs:{"small":"","color":item.colore}})],1),(_vm.mostraPrezzi)?_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.importo))]):_vm._e()])]}}])})],1)],1)],1)],1),_c('modifica-utente',{attrs:{"dialog":_vm.dialogModifica,"user":_vm.editItem,"tipologia":_vm.activeTab},on:{"chiudiDialog":_vm.chiudiDialog}}),_c('nuovo-menu',{attrs:{"dialog":_vm.dialogMenu},on:{"chiudi":_vm.chiudiDialogMenu}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }