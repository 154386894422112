<template>
  <v-dialog v-model="dialog" persistent width="800" @keydown.esc="annulla()">
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-btn icon @click="annulla()"> <v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="menuList"
          :headers="headersTabella"
          hide-default-footer
          :items-per-pge="999"
          dense
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.progOrder }}</td>
              <td>{{ item.text }}</td>
              <td>{{ item.type }}</td>
              <td>
                <v-icon> {{ item.img }} </v-icon>
              </td>
              <td><v-checkbox v-model="item.userActive" /></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="chiudiDialog()">Annulla</v-btn>
        <v-btn @click="salva()">SALVA</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_anagrafica } from "@/api/api.anagrafica.js";
import { get_menuUtente, set_menuUtente } from "@/api/api.user.js";

export default {
  props: {
    user: { type: Object, default: null },
    /* elencoMenu: { type: Array, default: () => [] }, */
    dialog: { type: Boolean, default: false },
    tipologia: { type: Number, default: -1 },
  },

  data: () => ({
    headersTabella: [
      { text: "Progressivo", value: "progOrder" },
      { text: "Nome", value: "text" },
      { text: "Tipo", value: "type" },
      { text: "Icona", value: "" },
      { text: "Abilitato", value: "" },
    ],
    menuUtente: [],
    menuList: [],

    elencoMenu: [],
  }),

  methods: {
    chiudiDialog() {
      this.$emit("chiudiDialog");
    },

    async caricaMenuUtente() {
      let res = await get_anagrafica("menu");
      this.elencoMenu = res.data;

      res = await get_menuUtente(this.user);
      this.menuUtente = res.data;
      this.elencoMenu.forEach((el) => {
        if (this.menuUtente.some((ell) => ell.menuName == el.menuName))
          el.userActive = true;
        else el.userActive = false;
      });

      this.menuList = this.elencoMenu;
    },

    async annulla() {
      let confirm = await this.$confirm.open(
        "Chiudi",
        "Eventuali modifiche andranno perse. Continuare?"
      );

      if (!confirm) return;
      this.chiudiDialog();
    },

    async salva() {
      let str = "";
      this.menuList.forEach((el) => {
        if (el.userActive) str += el.menuName + "|";
      });
      let par = {
        username: this.user.username,
        enabledMenu: str,
      };
      let res = await set_menuUtente(par);
      if (res.data == "OK") this.chiudiDialog();
      else this.$toast.alert(res.data);
    },
  },

  watch: {
    dialog() {
      if (this.dialog) {
        if (this.tipologia == 0) {
          this.menuList = [];
          this.caricaMenuUtente();
        }
      }
    },
  },
};
</script>