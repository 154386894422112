<template>
  <v-dialog v-model="dialog" width="675" persistent>
    <v-card>
      <v-card-title>
        <div>{{ nuovo ? "Nuovo " : "Modifica " }}menu</div>
        <v-spacer />
        <v-btn color="primary" text v-on:click="chiudi()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <div>
        <v-form ref="campiInserimento" lazy-validation v-model="valid">
          <v-card-text id="AreaInserimento">
            <!-- Area Caselle testo e select -->
            <div class="row ">
              <div class="col-6 d-flex">
                <v-text-field
                  class="col-12"
                  label="Nome"
                  required
                  :rules="Regole"
                  v-model="oggetto.nome"
                ></v-text-field>
              </div>
              <div class="col-6 d-flex">
                <v-text-field
                  class="col-12"
                  label="Testo"
                  required
                  :rules="Regole"
                  v-model="oggetto.testo"
                ></v-text-field>
              </div>
            </div>
            <!-- Select per Tipologia -->
            <div class="row justify-content-center mx-0">
              <div class="col-12">
                <v-select
                  :items="items"
                  v-model="oggetto.tipologia"
                  label="Tipologia"
                ></v-select>
              </div>
            </div>

            <!-- Icona - Link -->
            <div class="row">
              <div class="col-6 d-flex">
                <v-text-field
                  class="col-12"
                  v-model="oggetto.icona"
                  label="Icona"
                ></v-text-field>
              </div>
              <div class="col-6 d-flex">
                <v-text-field
                  class="col-12"
                  label="Link"
                  v-model="oggetto.link"
                  required
                  :rules="Regolelink"
                ></v-text-field>
              </div>
            </div>

            <!-- Colore - Progressivo -->
            <div class="row">
              <div class="col-6 d-flex">
                <v-text-field
                  class="col-12"
                  label="Colore"
                  required
                  :rules="Regole"
                  v-model="oggetto.colore"
                ></v-text-field>
              </div>
              <div class="col-6 d-flex">
                <v-text-field
                  class="col-12"
                  label="Progressivo"
                  type="number"
                  v-model="oggetto.progressivo"
                ></v-text-field>
              </div>
            </div>

            <!-- Area Pulsanti -->
            <v-row class="ma-0 pa-0">
              <v-btn color="primary" elevation="2" text v-on:click="chiudi()">
                Annulla
              </v-btn>
              <v-spacer />
              <v-btn color="primary" elevation="2" v-on:click="Salva()">
                SALVA
              </v-btn>
            </v-row>
          </v-card-text>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    nuovo: { type: Boolean, default: true },
  },
  data: () => ({
    oggetto: {
      nome: "",
      testo: "",
      tipologia: "item",
      icona: "",
      link: "",
      colore: "",
      progressivo: null,
    },
    //regole per il link 1
    Regolelink: [
      (v) => !!v || "Campo obbligatorio",
      (v) => /@|\/+./.test(v) || "Link non valido",
    ],
    Regole: [(v) => !!v || "Campo obbligatorio"],
    valid: true,

    items: [{ text: "item" }, { text: "header" }],
  }),
  methods: {
    chiudi() {
      this.resetValidation();
      this.$emit("chiudi");
    },

    Salva() {
      if (
        this.oggetto.nome == "" ||
        this.oggetto.nome == null ||
        this.oggetto.testo == "" ||
        this.oggetto.testo == null ||
        this.oggetto.tipologia == "" ||
        this.oggetto.tipologia == null ||
        this.oggetto.link == "" ||
        this.oggetto.link == null ||
        this.oggetto.colore == "" ||
        this.oggetto.colore == null
      ) {
        this.validazione();
      } else {
        console.log(this.oggetto);
        this.chiudi();
      }
    },

    validazione() {
      this.$refs.campiInserimento.validate();
    },

    resetValidation() {
      this.oggetto = {
        nome: "",
        testo: "",
        tipologia: "item",
        icona: "",
        link: "",
        colore: "",
        progressivo: null,
      };
      this.$refs.campiInserimento.resetValidation();
    },
  },
};
</script>
