import request from '@/services/interceptor.js'


// Calendar
export const get_calendar = (par) => {
    return request({
        url: '/calendar/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const get_clienti = () => {
    return request({
        url: '/calendar/clienti',
        method: 'get',
        loadingBar: true
    })
};

export const save_calendar = (par) => {
    return request({
        url: '/calendar',
        method: 'post',
        data: par,
        loadingBar: true
    })
};

export const del_calendar = (par) => {
    return request({
        url: '/calendar/' + par,
        method: 'delete',
        loadingBar: true
    })
};


export const get_utenti = () => {
    return request({
        url: '/calendar/utenti',
        method: 'get',
        loadingBar: true
    })
}