import request from '@/services/interceptor.js'


export const get_errors = (data) => {
    return request({
        url: '/tools/errors',
        method: 'post',
        data: data,
        loadingBar: true
    })
};


export const get_comunicazioni = () => {
    return request({
        url: '/tools/comunicazioni',
        method: 'get',
        loadingBar: true
    })
}

export const send_comunicazione = (data) => {
    return request({
        url: '/tools/comunicazioni/invia',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const save_comunicazione = (data) => {
    return request({
        url: '/tools/comunicazioni/salva',
        method: 'post',
        data: data,
        loadingBar: true
    })
};