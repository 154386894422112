<template src="./fatture.html"></template>

<script>
import { get_data, get_dettaglioDocu } from "@/api/api.documenti.js";
import { get_clienti } from "@/api/api.anagrafica.js";
//import { get_utenti } from "@/api/api.calendar.js";

import mixinDocu from "./mixinDocu.js";

import GraficoFatturato from "@/components/dialog/documenti/GraficoFatturato";

export default {
  name: "PageFatture",

  mixins: [mixinDocu],

  components: {
    GraficoFatturato
  },

  data: () => ({
    filtri: { anno: new Date().getFullYear(), cdContatto: "", raggruppaClienti: false, meseStr: null, mese: null, operatore: [] },
    search: "",
    elencoAnni: [],
    elencoClienti: [],
    elencoMesi: [],
    elencoOperatori: [],

    elencoDati: [],
    headersDati: [],
    dettaglioDocu: [],
    headersDettaglio: [],

    expanded: [],
    importoTotale: 0,

    dialogGrafico: false,
  }),

  methods: {
    async caricaDati() {
      if (!this.filtri.raggruppaClienti)
        this.headersDati = this.getHeadersDati();
      else
        this.headersDati = this.getHeadersDatiGruppo();

      this.headersDettaglio = this.getHeadersDettaglio(this.filtri.raggruppaClienti);

      this.filtri.mese = this.elencoMesi.indexOf(this.filtri.meseStr);
      let res = await get_data("fatture/elenco", this.filtri);
      this.elencoDati = res.data;
      this.importoTotale = 0;
      this.elencoDati.forEach((el) => {
        this.importoTotale += el.importoTot;
      });
      this.importoTotale = this.$functions.numberWithCommas(
        this.importoTotale.toFixed(2)
      );
    },

    caricaAnni() {
      for (let i = this.filtri.anno; i >= 2019; i--) {
        this.elencoAnni.push(i);
      }
    },

    /* async caricaOperatori() {
      let res = await get_utenti();
      this.elencoOperatori = res.data;
    }, */

    async caricaClienti() {
      let res = await get_clienti();
      this.elencoClienti = res.data;
    },

    refresh() {
      this.caricaDati();
    },

    async caricaInfo(item) {
      this.elencoDati.forEach((el) => {
        if (el != item) el.expanded = false;
      });
      item.expanded = !item.expanded;

      this.expanded = [];
      if (item.expanded) {
        this.expanded.push(item);
        let res = {};
        let mese = this.filtri.mese + 1;
        if (!this.filtri.raggruppaClienti)
          res = await get_dettaglioDocu("fatture", item.nrDocu + "/" + mese);
        else
          res = await get_dettaglioDocu("fatture/gruppo/" + item.codAnagrafica, this.filtri.anno + "/" + mese)
        this.dettaglioDocu = res.data;
      } else this.dettaglioDocu = [];
    },

    getTotRigo(item) {
      return this.$functions.numberWithCommas(
        (item.prUnitario * item.qtOrdinata).toFixed(2)
      );
    },

    mostraGrafico() {
      this.dialogGrafico = true;
    },

    chiudiGrafico() {
      this.dialogGrafico = false;
    },

  },

  mounted() {
    this.caricaAnni();
    this.caricaClienti();
    this.caricaDati();
  },

  created() {
    this.headersDati = this.getHeadersDati();
    this.elencoMesi = this.$i2kFunctions.getMonths();
    //this.caricaOperatori();
  },
};
</script>