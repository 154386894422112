import request from '@/services/interceptor.js'



// Utente
export const get_datiUserInfo = () => {
    return request({
        url: '/user/info',
        method: 'get',
        loadingBar: true
    })
};



export const get_menuUtente = (data) => {
    return request({
        url: '/user/menu',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const set_menuUtente = (data) => {
    return request({
        url: '/user/edit/menu',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const save_userAccount = (par) => {
    return request({
        url: '/user/account/save',
        method: 'post',
        data: par,
        loadingBar: true
    })
};


export const save_immagineAccount = (par, data) => {
    return request({
        url: '/user/account/upload/profilo/' + par,
        method: 'post',
        data: data,
        noLoadingBar: true
    })
}


export const get_immagineAccount = (par) => {
    return request({
        url: '/user/account/download/profilo/' + par,
        method: 'get',
        noLoadingBar: true
    })
}

export const del_immagineAccount = (par) => {
    return request({
        url: 'user/account/elimina/profilo/' + par,
        method: 'delete',
        noLoadingBar: true,
    })
}