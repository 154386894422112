<template src="./strumenti.html"></template>

<script>
import { validate, format, generate } from "@/utils/validator.js";

import * as he from "he";

export default {
  data: () => ({
    output: "",
    showHelp: false,

    urlText: "",
    urlResult: "",

    htmlText: "",
    htmlResult: "",
    filters: {
      decimal: false,
      namedReferences: false,
      encodeEverything: false,
    },
    htmlTextPreview: '',
    previewHtml: '',
  }),

  methods: {
    calcolaVersione() {
      let input = document.getElementById("input");

      let { value } = input;
      this.output = validate(value) ? format(value) : generate(value);
    },

    encode() {
      this.urlResult = encodeURIComponent(this.urlText);
    },

    decode() {
      try {
        this.urlResult = decodeURIComponent(this.urlText);
      } catch (error) {
        console.error(error);
        this.$toast.alert("Errore nella decodifica dell'URI")
      }
    },

    escapeHTML() {
      if (this.htmlText == null) {
        this.htmlResult = "";
        return;
      }
      this.htmlResult = he.encode(this.htmlText, {
        useNamedReferences: this.filters.namedReferences,
        decimal: this.filters.decimal,
        encodeEverything: this.filters.encodeEverything,
      });
    },

    async copyText(text) {
      if (text == null || text == "") return;
      try {
        await navigator.clipboard.writeText(text);
        this.$toast.info('Testo copiato negli appunti');
      } catch (e) {
        console.error(e);
      }
    },

    updatePreview() {
      this.previewHtml = this.htmlTextPreview;
    },
  },
};
</script>