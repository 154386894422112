<template>
  <!-- Dialog -->
  <v-dialog v-model="getDialog" width="500" persistent @keydown.esc="chiudi()">
    <v-form ref="formIns" v-on:submit.prevent="formSubmit()" lazy-validation>
      <v-card>
        <v-card-title
          class="headline pa-3"
          style="background-color: #424242 !important; color: #fff !important"
          primary-title
          >{{ $functions.formatISODate(data) }} - {{ utente }}

          <v-select
            :items="tipiOre"
            solo
            v-model="dati.tipoOre"
            hide-details
            style="margin-left: 10px; max-width: 150px"
            v-on:change="impostaDescrizione"
          />
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-autocomplete
                  :disabled="dati.tipoOre != 'Lavoro'"
                  :items="clienti"
                  label="Cliente"
                  item-text="ragSoc"
                  item-value="ragSoc"
                  v-model="clienteSel"
                  return-object
                  ref="inputCliente"
                  clearable
                  v-on:change="setCliente()"
                  autofocus
                />
              </v-col>

              <v-col cols="12" class="py-0">
                <v-container fluid class="px-0">
                  <v-row>
                    <v-col xs4 class="py-0">
                      <v-text-field v-model="dati.ore" label="Ore" required />
                    </v-col>
                    <v-col xs4 class="py-0">
                      <v-text-field v-model="dati.minuti" label="Minuti" />
                    </v-col>
                    <v-col xs4 class="py-0">
                      <v-text-field
                        :disabled="dati.tipoOre != 'Lavoro'"
                        v-model="dati.importo"
                        label="Importo"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-container fluid class="px-0">
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <v-select
                        v-bind:items="tipiImporto"
                        v-model="dati.tipoImporto"
                        label="Tipo Importo"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-select
                        :disabled="dati.tipoOre != 'Lavoro'"
                        v-bind:items="destinazioni"
                        v-model="dati.destinazione"
                        label="Destinazione"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-textarea
                  outlined
                  label="Descrizione"
                  v-model="dati.descrizione"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn color="primary" text @click="chiudi()">Annulla</v-btn>
          <v-btn @click="elimina" text icon>
            <v-icon>delete</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn color="primary" type="submit">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  get_calendar,
  save_calendar,
  del_calendar,
} from "@/api/api.calendar.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    data: { type: String, default: "" },
    prog: { type: Number, default: 0 },
    clienti: {
      type: Array,
      default: () => {
        return [];
      },
    },
    utente: { type: String, default: "" },
  },

  data: () => ({
    dati: {
      data: "",
      prog: 0,
      descrizione: "",
      ore: 0,
      minuti: 0,
      utente: "",
      importo: 0,
      tipoImporto: "",
      destinazione: "",
      tipoOre: "",
    },

    clienteSel: {},
    tipiImporto: ["Orario", "Totale"],
    destinazioni: ["Cliente", "Ufficio"],
    tipiOre: ["Lavoro", "Ferie", "Permesso", "Mutua"],

    desc: ["Modifiche software", "Ferie", "Permesso", "Mutua"],

    rules: {
      required: (value) => !!value || "Richiesto.",

      number: (value) => {
        return (
          (/^\d+$/g.test(value) && parseInt(value) > 0) || "Numero non valido."
        );
      },

      numberOptional: (value) => {
        if (value == undefined) return true;
        if (value.length == 0) return true;
        if (!/^\d+$/g.test(value) || parseInt(value) < 0)
          return "Numero non valido.";
      },
    },
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.clienteSel = [];

      // Controllo nuovo
      if (this.prog == -1) {
        this.dati.data = this.data;
        this.dati.prog = this.prog;
        this.dati.codAnagrafica = "";
        this.dati.tipoOre = "Lavoro";

        this.dati.descrizione = this.desc[0];

        // Ricavo il giorno della settimana : sabato imposto solo 4 ore
        const date = new Date(this.data);
        const day = date.getDay();

        this.dati.ore = day == 6 ? 4 : 8;
        this.dati.minuti = 0;
        this.dati.utente = this.utente;
        this.dati.importo = 0;
        this.dati.tipoImporto = "Orario";
        this.dati.destinazione = "Cliente";
      } else {
        let par = this.data + "/" + this.prog;

        try {
          let res = await get_calendar(par);

          this.dati = res.data;

          // Seleziono il cliente
          this.clienteSel = this.clienti.find(
            (f) => f.codAnagrafica == this.dati.codAnagrafica
          );
        } catch (error) {
          console.log(error);
          this.$toast.info(this.$functions.getErrorMsg(error));
        }
      }
    },

    async formSubmit() {
      // Validazione
      if (this.$refs.formIns.validate() == false) return;
      this.normalizeInput();

      try {
        await save_calendar(this.dati);

        this.chiudi(true);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$functions.getErrorMsg(error));
      }
    },

    normalizeInput() {
      if (this.dati.tipoOre != "Lavoro") {
        this.dati.importo = 0;
        this.dati.codAnagrafica = "";
        this.dati.destinazione = "";
      }
    },

    chiudi(aggiorna) {
      this.$emit("chiudi", aggiorna);
    },

    setCliente() {
      if (!this.clienteSel) {
        this.dati.importo = 0;
        this.dati.codAnagrafica = "";
      } else {
        this.dati.importo = this.clienteSel.importo;
        this.dati.codAnagrafica = this.clienteSel.codAnagrafica;
      }
    },

    async elimina() {
      if (this.prog == -1) return;

      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare l'evento ?"
      );

      if (!confirm) return;

      try {
        await del_calendar(this.data + "/" + this.prog);

        // Chiudo la videata
        this.chiudi(true);
      } catch (error) {
        this.$toast.alert(this.$functions.getErrorMsg(error));
      }
    },

    impostaDescrizione() {
      this.dati.descrizione = this.desc[
        this.tipiOre.indexOf(this.dati.tipoOre)
      ];
    },
  },

  mounted() {},

  computed: {
    getDialog: {
      get: function() {
        return this.dialog;
      },
      set: function(value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$nextTick(() => this.$refs.formIns.resetValidation());

        this.$nextTick(() => this.$refs.inputCliente.focus());

        this.caricaDati();
      }
    },
  },
};
</script>
