import { Bar } from 'vue-chartjs'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Bar,

  props: ["data", "options"],

  components: {
    ChartJsPluginDataLabels,
  },

  async mounted() {
    await this.renderChart(this.data, this.options)
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.data, this.options)
  },
}