import {
  get_datiUserInfo
} from "@/api/api.user.js";

import storage from "@/services/storage";

const user = {
  namespaced: true,

  state: {
    user: '',
    role: '',
    eMail: '',
    menu: [],
    imgProfilo: "",
  },

  mutations: {
    SET_USER: (state, user) => {

      state.user = user.username
      state.role = user.role
      state.menu = user.menu
      state.eMail = user.eMail
      storage.setUsername(user.username)
      storage.setRole(user.role)
      state.imgProfilo = user.imgProfilo
    },

    SET_IMGPROFILO: (state, data) => {
      state.imgProfilo = data
    }

  },

  actions: {

    userInfo({
      commit,
      dispatch
    }) {

      return new Promise((resolve, reject) => {

        get_datiUserInfo().then(resp => {

          commit("SET_USER", resp.data)

          resolve(resp)

        }).catch(err => {

          reject(err)
        })
      })

    },


  },

  getters: {
    getRole: state => {
      return state.role
    },

    getTitolo: state => link => {

      let itemFind = state.menu.find(x => x.Link === link);
      if (itemFind)
        return itemFind.Text + '|' + itemFind.Img;

      return ""
    },

    getNome: state => {
      return state.user
    },

    getMenu: state => {
      return state.menu
    },

    getEmail: state => {
      return state.eMail
    },
    getImgProfilo: state => {
      return state.imgProfilo
    },
    getUsername: state => {
      return state.username
    },

  }
}


export default user