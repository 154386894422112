function a(a) {
    return a > 9 ? `${a}` : `0${a}`;
}

function b(a) {
    return a > 99 ? `${a}` : a > 9 ? `0${a}` : `00${a}`;
}

function c(a) {
    let b = d();
    a || (a = {}), "string" == typeof a && (a = {
        version: a
    });
    let c = a.version;
    if (c) {
        let e = a.versionSeparator || ".";
        b = c.trim() + e.trim() + b;
    }
    return b;
}

function d() {
    let c = new Date,
        d = c.getFullYear() % 100,
        e = c.getMonth() + 1,
        f = c.getDate(),
        g = parseInt((60 * c.getHours() + c.getMinutes()) / 2);
    return `${a(d)}${a(e)}${a(f)}${b(g)}`;
}

function e(a) {
    let b = /(\d{2})(\d{2})(\d{2})(\d{3})$/.exec(a.trim());
    if (!b) throw Error('Build number has to end with "YYMMDDCCC".');
    let c = +b[1],
        d = +b[2],
        e = +b[3],
        f = +b[4];
    if (!(c >= 0 && d >= 1 && d <= 12 && e >= 1 && e <= 31)) throw Error("Build number has to contain numbers formatting a date.");
    return {
        year: c,
        month: d,
        day: e,
        counter: f
    };
}

function f(a) {
    try {
        return e(a), !0;
    } catch {
        return !1;
    }
}

function g(a) {
    let {
        year: b,
        month: c,
        day: d,
        counter: f
    } = e(a);
    b += 2e3, c -= 1, f *= 2;
    let g = parseInt(f / 60),
        h = parseInt(f % 60);
    return new Date(b, c, d, g, h);
}

function h(b) {
    "string" == typeof b && (b = g(b));
    let c = b.toDateString(),
        d = b.getHours(),
        e = b.getMinutes();
    return `${c} ${a(d)}:${a(e)}`;
}
export {
    h as format, c as generate, g as parse, f as validate
}